import { ApiErrorCode } from "@justplayfair/model";
import ky from "ky";
import { sentryCaptureError } from "../errors/errors.handler";
import { authService } from "@services/auth";

export class HTTPError extends Error {
  response: Response;
  apiErrorCode: ApiErrorCode | undefined;
  constructor(response: Response, apiErrorCode?: ApiErrorCode) {
    // Set the message to the status text, such as Unauthorized,
    // with some fallbacks. This message should never be undefined.
    super(
      response.statusText ||
        String(
          response.status === 0 || response.status
            ? response.status
            : "Unknown response error"
        )
    );
    this.name = "HTTPError";
    this.apiErrorCode = apiErrorCode;
    this.response = response;
  }
}
export const httpClient = ky.create({
  credentials: "include",
  mode: "cors",
  throwHttpErrors: false,
  retry: 0,
  timeout: 3000,
  hooks: {
    beforeRequest: [
      (request) => {
        if (authService.isAuthenticated) {
          request.headers.set("authorization", `Bearer ${authService.token}`);
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (!response || Math.round(response.status / 100) === 5) {
          const technicalHttpError = new HTTPError(response);
          sentryCaptureError(technicalHttpError);
          throw technicalHttpError;
        }
        if (!response.ok) {
          const error = await response.json();
          const apiErrorCode =
            error instanceof Object && "apiErrorCode" in error
              ? (error.apiErrorCode as ApiErrorCode)
              : undefined;
          throw new HTTPError(response, apiErrorCode);
        }
        return response;
      },
    ],
  },
});
